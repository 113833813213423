<div class="container-fluid bg-image text-center">
    <h1 class="custom-h1">GruppoEquinox <br> Chi siamo <br> <a href="https://wikitarot.it/" class="btn btn-custom" style="text-decoration: none;">Visita WikiTarot</a></h1>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <h3 class="text-dark custom-h3" style="text-align: justify; font-weight: 300;">
                <br>
                WikiTarot non è un semplice sito di cartomanzia, ma un vero e proprio portale esoterico in grado di
                abbracciare e fondere insieme varie discipline divinatorie, nelle diverse fasi della vostra vita, in
                base all`esperienza che state vivendo in questo momento. <br> <br>

                In tale prospettiva, desideriamo accompagnarvi nel percorso più adatto a risolvere i vostri problemi, in
                ogni ambito: amore, lavoro, soldi, realizzazione personale, etc...
                Come? Da un punto di vista spirituale, è risaputo che ognuno di noi ha un proprio destino che prevede
                tappe, più o meno obbligate, per la propria evoluzione interiore. Quindi, noi ti seguiremo sia che tu
                abbia bisogno di fare chiarezza sul tuo cammino, prendere decisioni, conoscere il tuo futuro, con la
                cartomanzia e l'astrologia; sia che tu voglia resistere, perché ritieni di dover ancora lottare per
                ottenere ciò che desideri, con la magia; sia che tu abbia deciso di accogliere e lasciar andare le
                situazioni dolorose, attraverso un percorso di rinascita guidato da esperti del settore olistico, verso
                la consapevolezza.  <br> <br>

                Abbiamo dedicato una apposita sezione all'interpretazione dei sogni, ricchi di valore intrinseco per
                comprendere il nostro presente, spesso sulla base del passato, in funzione del nostro futuro. <br> <br>

                Infine, chi di noi, nei momenti difficili, di transizione, di forte stress, non avverte il desiderio di
                cambiare il proprio aspetto esteriore, per trovare un equilibrio con quello interiore? Anche qui,
                veniamo in vostro soccorso, con la sapiente esperienza delle nostre consulenti d'immagine. Sulla base
                del vostro segno zodiacale e ascendente, che forniscono indicazioni preziose sul vostro aspetto
                caratteriale e comportamentale, nonché sulla base delle vostre caratteristiche fisiche, sapremo
                valorizzare i vostri punti di forza per creare il vostro nuovo, personalissimo look. Vi presenterete
                agli altri con una rinnovata immagine che, anch'essa, a suo modo, influenzerà il vostro futuro,
                cambiando l'approccio con il mondo che vi circonda. <br>
            </h3>
        </div>
    </div>
</div>